//
// img-comp.js
//

const inputs = document.querySelectorAll('.img-comp-input');

function comp(el) {
  const front = el.parentElement.querySelector('.img-comp-front');
  const handle = el.parentElement.querySelector('.img-comp-handle');

  front.style.maxWidth = el.value + '%';
  handle.style.left = el.value + '%';
}

inputs.forEach((input) => {
  'input change'.split(' ').forEach(function (event) {
    input.addEventListener(event, function () {
      comp(input);
    });
  });
});

const lazyImages = document.querySelectorAll('img.lazy');

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const img = entry.target;
      img.src = img.dataset.src;
      img.classList.remove('lazy');
      observer.unobserve(img);
    }
  });
});

lazyImages.forEach(image => {
  observer.observe(image);
});