//
// user.js
// Use this to write your custom JS
//

import { Offcanvas } from 'bootstrap';

const modalShoppingCart = document.querySelector('#modalShoppingCart');
const userHash = window.location.hash.toLocaleLowerCase()
switch (userHash) {
    case '#success':
        if (location.pathname.toLocaleLowerCase() != '/cart')
            new Offcanvas(modalShoppingCart).show();
        modalShoppingCart.addEventListener('hidden.bs.offcanvas', function () {
            history.go(-1);
        })
        break;
    case '#nologin':
        document.querySelector('.login-alert').classList.remove('d-none');
        break;
    case '#decline':
        document.querySelector('.payment-alert').classList.remove('d-none');
        break;
    case '#billingsuccess':
        document.querySelector('.billing-address-alert').classList.remove('d-none');
        break;
    case '#passwordsuccess':
        document.querySelector('.password-success-alert').classList.remove('d-none');
        break;
    case '#passwordnosuccess':
        document.querySelector('.password-failure-alert').classList.remove('d-none');
        break;
    case '#accountexists':
        document.querySelector('.account-exists-alert').classList.remove('d-none');
        break;
    case '#notfound':
        document.querySelector('.trackorder-alert').classList.remove('d-none');
        break;

    default:
        break;
}

const cartBlock = document.querySelector('.add-to-cart-block');
const cartParentBlock = cartBlock ? document.querySelector('.add-to-cart-block').closest('section') : null;
const footerBlock = document.querySelector('footer');

function isVisibleInViewport(elem) {
    var y = elem.offsetTop;
    var height = elem.offsetHeight;
    while (elem = elem.offsetParent)
        y += elem.offsetTop;
    var maxHeight = y + height;
    var isVisible = (y < (window.pageYOffset + window.innerHeight)) && (maxHeight >= window.pageYOffset);
    return isVisible;
}

function isVisibleCheck() {
    if (isVisibleInViewport(cartParentBlock) || isVisibleInViewport(footerBlock))
        cartBlock.classList.remove('add-to-cart-sticky');
    else
        cartBlock.classList.add('add-to-cart-sticky');
}

if (cartParentBlock) {
    const cartForm = document.querySelector('.add-to-cart-block').closest('form');
    cartForm.style.minHeight = `${cartForm.offsetHeight}px`;
    window.addEventListener('scroll', isVisibleCheck, false);
}

const radios = document.querySelectorAll('input[name="payment"]');
const orderEls = document.querySelectorAll('.order-btn');
if (radios.length > 0) {
    radios.forEach(function (radio) {
        radio.addEventListener('click', function () {
            if (orderEls.length > 0) {
                orderEls.forEach((orderEl) => {
                    if (radio.id == "checkoutPayPal") {
                        try {
                            orderEl.classList.remove('btn-dark');
                            orderEl.classList.add('btn-linkedin');
                            orderEl.classList.add('d-flex');
                            orderEl.classList.add('gap-1');
                            orderEl.classList.add('align-items-center');
                            orderEl.classList.add('justify-content-center');
                            orderEl.innerHTML = '<img style="height:20px" src="/assets/img/payment/paypal-white.svg" alt="" role="presentation" class="paypal-logo paypal-logo-paypal paypal-logo-color-white">' + ' Checkout';
                        } catch (error) { }
                    } else {
                        try {
                            orderEl.classList.remove('btn-linkedin');
                            orderEl.classList.remove('d-flex');
                            orderEl.classList.remove('gap-1');
                            orderEl.classList.remove('align-items-center');
                            orderEl.classList.remove('justify-content-center');
                            orderEl.classList.add('btn-dark');
                            orderEl.innerHTML = '<i class="fe fe-lock fs-lg mx-3"></i>' + 'Place Order';
                        } catch (error) { }
                    }
                })
            }
        });
    });
}
