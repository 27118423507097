//
// simplebar.js
//

const toggles = document.querySelectorAll('[data-bs-toggle="modal"]');

toggles.forEach((toggle) => {
  const targetAction = toggle.dataset.action;
  if(targetAction == "video") {
    
    const target = toggle.getAttribute("href");
    const targetEl = document.querySelector(target);
    const targetVideo = toggle.dataset.src.split("vimeo.com/").pop();

    if (targetEl) {
      targetEl.addEventListener('shown.bs.modal', () => {
        targetEl.querySelector("iframe").src=`https://player.vimeo.com/video/${targetVideo}?autoplay=1&hd=1&show_title=1&show_byline=1&show_portrait=0&fullscreen=1`;
      });
    
      targetEl.addEventListener('hidden.bs.modal', () => {
        targetEl.querySelector("iframe").src="";
      });
    }
  }

});
