//
// form.js
//

const toggles = document.querySelectorAll('[data-toggle="form-caption"]');

toggles.forEach((toggle) => {
  toggle.addEventListener('change', function () {
    const target = document.querySelector(toggle.dataset.target);
    const value = toggle.value;

    target.innerHTML = value;
  });
});

// Fetch all the forms we want to apply custom Bootstrap validation styles to
const forms = document.querySelectorAll('.needs-validation')

// Loop over them and prevent submission
Array.prototype.slice.call(forms)
  .forEach(function (form) {

    // Get password element
    const confirmPasswordEl = form.querySelector('[data-match]');
    const passwordEl = confirmPasswordEl ? form.querySelector(confirmPasswordEl.getAttribute('data-match')) : null;

    const confirmPasswordHandleInput = function () {
  
      if (confirmPasswordEl && passwordEl) {
        if (confirmPasswordEl.value != passwordEl.value) {
          confirmPasswordEl.setCustomValidity('Passwords do not match');
        } else {
          confirmPasswordEl.setCustomValidity('');
        }
      }
    }

    // Get date element for valid year and month
    const dateMonthEl = form.querySelector('[data-valid-date="Month"]');
    const dateYearEl = form.querySelector('[data-valid-date="Year"]');

    const validYearMonthHandleInput = function () {
  
      if (dateMonthEl && dateYearEl) {

        const m = dateMonthEl.value * 1;
        const y = dateYearEl.value * 1;

        const current_date = new Date();
        const expiration_date = new Date(new Date(y, m) - 1);

        if (current_date > expiration_date) {
          dateMonthEl.setCustomValidity('Expired date!');
          dateYearEl.setCustomValidity('Expired date!');
        } else {
          dateMonthEl.setCustomValidity('');
          dateYearEl.setCustomValidity('');
        }
      }
    }

    // Get card number element for validating cc number
    const cardNumberEl = form.querySelector('[data-valid-card-number]');

    const checkValidCard = function () {
      if (cardNumberEl) {
        const acceptedCreditCards = {
          'visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
          'mastercard': /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
          'amex': /^3[47][0-9]{13}$/,
          'discover': /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
          'diners_club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
          'jcb': /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
        };

        // remove all non digit characters
        const value = cardNumberEl.value.replace(/\D/g, '');
        let sum = 0;
        let shouldDouble = false;
        // loop through values starting at the rightmost side
        for (let i = value.length - 1; i >= 0; i--) {
          let digit = parseInt(value.charAt(i));

          if (shouldDouble) {
            if ((digit *= 2) > 9) digit -= 9;
          }

          sum += digit;
          shouldDouble = !shouldDouble;
        }
        
        const valid = (sum % 10) == 0;
        let accepted = false;
        
        // loop through the keys (visa, mastercard, amex, etc.)
        Object.keys(acceptedCreditCards).forEach(function(key) {
          var regex = acceptedCreditCards[key];
          if (regex.test(value)) {
            accepted = true;
          }
        });
        
        if (valid && accepted) {
          cardNumberEl.setCustomValidity('');
        } else {
          cardNumberEl.setCustomValidity('Invalid Card');
        }
      }
    }

    // Get place order element for disable
    const orderEls = form.querySelectorAll('.order-btn');

    form.addEventListener('submit', function (event) {

      const chkPaypal = document.getElementById('checkoutPayPal');
      const chkCardCollapse = document.getElementById('checkoutPaymentCardCollapse');

      if (chkPaypal) {
        if (chkPaypal.checked) {
          chkCardCollapse.remove();
        }
      }

      confirmPasswordHandleInput();
      validYearMonthHandleInput();
      checkValidCard();
      
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        if (orderEls.length > 0) {
          orderEls.forEach((orderEl)=>{
            orderEl.disabled = true;
            orderEl.innerText = 'Submitting order...';
          })
        }
      }

      if (confirmPasswordEl && passwordEl) {
        passwordEl.addEventListener('input', confirmPasswordHandleInput);
        confirmPasswordEl.addEventListener('input', confirmPasswordHandleInput);
      }

      if (dateMonthEl && dateYearEl) {
        dateMonthEl.addEventListener('input', validYearMonthHandleInput);
        dateYearEl.addEventListener('input', validYearMonthHandleInput);
      }
      
      if (cardNumberEl) {
        cardNumberEl.addEventListener('input', checkValidCard);
      }

      form.classList.add('was-validated');
      form.querySelectorAll(".form-control:invalid")[0]?.scrollIntoView({ behavior: "smooth" });
    }, false)
  })
