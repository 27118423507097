const toggles = document.querySelectorAll('.toggle-password');

toggles.forEach((toggle) => {
    const target = toggle.dataset.target;
    const targetEl = document.querySelector(target);

    toggle.addEventListener("click", togglePassword);
    function togglePassword() {
        if (targetEl) {
            targetEl.type = targetEl.type === "password" ? "text" : "password";
        }
    }
  
});
  