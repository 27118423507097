const numberUpDowns = document.querySelectorAll('[data-number-updown]');

numberUpDowns.forEach((numberUpDown) => {

    function updownVal() {
        const attrVal = numberUpDown.getAttribute('data-number-updown') * 1;
    
        let numberEl = null;
        if (attrVal == 1) {
            numberEl = numberUpDown.previousElementSibling;
        } else if (attrVal == -1) {
            numberEl = numberUpDown.nextElementSibling;
        }
    
        if (numberEl) {
            let val = numberEl.value * 1 + attrVal;
            if (val >= 1) {
                numberEl.value = val;
                let changeEvent = new Event('change');
                numberEl.dispatchEvent(changeEvent);
            }
        }
    }

    numberUpDown.addEventListener('click', updownVal, false);


});
